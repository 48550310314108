var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',[_c('v-col',{class:_vm.$vuetify.breakpoint.smAndUp ? 'py-10' : '',attrs:{"cols":"12"}},[_c('video',{staticClass:"IntroVideo",style:({
          cursor: 'pointer',
          marginLeft: _vm.$vuetify.breakpoint.smAndUp ? '14%' : '5%',
          marginTop: _vm.$vuetify.breakpoint.smAndUp ? '-2%' : '8%',
        }),attrs:{"width":_vm.$vuetify.breakpoint.smAndUp ? '90%' : '90%',"height":"100%","poster":require("@/assets/publicDonationPage/timelineEvent/introd1.png")},on:{"click":_vm.openVideo}},[_c('source',{attrs:{"src":"https://video.harlemwizardsinabox.com/introductionvideo.mp4","type":"video/mp4"}})]),_c('img',{style:(_vm.$vuetify.breakpoint.smAndUp
            ? 'margin-top: -55%; margin-left: 51%; cursor: pointer'
            : 'position:relative;bottom:60%;left:48%'),attrs:{"src":require("@/assets/play.png"),"alt":"Play button","width":_vm.$vuetify.breakpoint.smAndUp ? '70' : '50',"height":_vm.$vuetify.breakpoint.smAndUp ? '70' : '50'},on:{"click":_vm.openVideo}})])],1),(_vm.imageViewer.flag)?_c('full-screen-video-viewer',{attrs:{"imageViewer":_vm.imageViewer}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }