<template>
  <div>
    <v-row>
      <v-col cols="12" :class="$vuetify.breakpoint.smAndUp ? 'py-10' : ''">
        <!-- Video -->
        <video
          @click="openVideo"
          :style="{
            cursor: 'pointer',
            marginLeft: $vuetify.breakpoint.smAndUp ? '14%' : '5%',
            marginTop: $vuetify.breakpoint.smAndUp ? '-2%' : '8%',
          }"
          :width="$vuetify.breakpoint.smAndUp ? '90%' : '90%'"
          height="100%"
          class="IntroVideo"
          poster="@/assets/publicDonationPage/timelineEvent/introd1.png"
        >
          <source
            src="https://video.harlemwizardsinabox.com/introductionvideo.mp4"
            type="video/mp4"
          />
        </video>

        <!-- Play Button Overlay -->
        <img
          src="@/assets/play.png"
          alt="Play button"
          :width="$vuetify.breakpoint.smAndUp ? '70' : '50'"
          :height="$vuetify.breakpoint.smAndUp ? '70' : '50'"
          @click="openVideo"
          :style="
            $vuetify.breakpoint.smAndUp
              ? 'margin-top: -55%; margin-left: 51%; cursor: pointer'
              : 'position:relative;bottom:60%;left:48%'
          "
        />
      </v-col>
    </v-row>

    <!-- Full-Screen Video Viewer -->
    <full-screen-video-viewer
      :imageViewer="imageViewer"
      v-if="imageViewer.flag"
    ></full-screen-video-viewer>
  </div>
</template>

<script>
export default {
  name: "IntroVideoPdpDistrict",
  components: {
    FullScreenVideoViewer: () =>
      import("@/components/VideoPlayer/FullScreenImageViewer"),
  },
  data() {
    return {
      imageViewer: {
        src: "",
        flag: false,
        type: "video",
      },
    };
  },
  methods: {
    openVideo() {
      console.log("video opened");
      // Use the static video URL when opening the full-screen viewer
      this.imageViewer.src =
        "https://video.harlemwizardsinabox.com/introductionvideo.mp4";
      this.imageViewer.flag = true;
    },
  },
};
</script>
<style scoped>
.IntroVideo {
  max-width: 100%;
  border-radius: 8px;
  overflow: hidden;
}
</style>
